html,
body {
    height: 100%;
    max-height: 100%;
}

body {
    font-family: "Source Sans Pro", sans-serif;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.card-header {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 22px;
}

// Defines the main dashboard container
.dashboard {
    position: relative;
    display: flex;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
}

// Sidebar on the left should take 1/3 of the page width
.sidebar {
    background-color: #7ab800;
    color: #F2FFD9;
    padding: 10px 32px;
    min-width: 480px;
    width: 40%;
    flex-shrink: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

// Main area should take 2/3 of the page width
.main {
    background-color: #fff;
    padding: 0;
    width: 100%; // we can set this to 100%, the element will take the available space
}

.dashboard .powered {
    position: absolute;
    bottom: 12px;
    right: 12px;
    font-size: 24px;

    height: 40px;

    img {
        width: auto;
        height: 100%;
    }
}

.spinner {

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    svg {
        display: block;
        fill: #fff;
        width: 90px;
        height: 90px;
        opacity: 0.7;
        animation: spin ease-out .75s infinite;
        margin: 0 auto;
    }
}


@import "connect.less";
@import "events.less";