.event {
    display: flex;
    align-items: center;

    .date {
        text-align: center;
        line-height: 1;

        .day {
            display: block;
            font-weight: 900;
            font-size: 72px;
        }

        .month {
            display: block;
            font-weight: 900;
            font-size: 42px;
            margin-top: -8px;
        }
    }

    .information {
        line-height: 1;
        margin-left: 22px;
        vertical-align: middle;

        h2 {
            margin: 4px 0;
            font-size: 34px;
        }

        .time {
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 1px;
        }

        .location {
            display: flex;
            align-items: center;
        }

        .room {
            font-size: 20px;
            font-weight: 700;
            margin-left: 12px;
            background: #F2FFD9;
            color: #223300;
            text-transform: uppercase;
            padding: 4px 18px;
            border-radius: 8px;
            letter-spacing: 1px;
        }

        .meta {
            font-size: 24px;
        }
    }
}