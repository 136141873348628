.connect-wrapper {
    transition: transform ease .5s;
}

.startup {
    position: relative;
    display: flex;
    align-items: center;
    height: 50%;
    background: #DADEDE;
    padding: 10px 32px;

    &:nth-child(odd) {
        background: #fff;
    }

    &.is-perk {
        border: 8px solid #0081bf;
    }

    .logo {
        background: white;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
        width: 360px;
        height: 360px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-items: center;
        padding: 12px;

        img {
            margin: 0 auto;
            max-width: 80%;
            max-height: 80%;
        }
    }

    .perk-stamp {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 120px;

        transform: rotate(11deg);

        img {
            width: 100%;
            height: 100%;
        }
    }

    .information {
        margin-left: 32px;

        h2 {
            font-size: 46px;
            margin: 0 0 12px;
        }

        p {
            font-size: 28px;
            line-height: 1.3;
        }

        .industry {
            font-size: 20px;
            font-weight: 700;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            text-transform: uppercase;
            padding: 4px 18px;
            border-radius: 8px;
            letter-spacing: 1px;

            &.is-perk {
                background: rgba(51, 182, 214, .5);
            }
        }

        .meta {
            font-size: 24px;
            margin-left: 24px;
            max-width: 200px;
        }

        .icon {
            height: 20px;
            width: auto;
            margin-right: 4px;
            display: inline-block;
        }
    }
}